var App = {
    init: function () {
        this.scripts();
        // this.popups();
    },
    loaded: function () {},
    scripts: function () {
        // Is touch device?

        if ('ontouchstart' in window || window.navigator.MaxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0) {
            document.body.classList.add('touch');
        } else {
            document.body.classList.add('no-touch');
        }

        // Document has scrolled

        window.addEventListener('scroll', function () {
            if (window.pageYOffset >= 50) {
                document.body.classList.add('page-scrolled');
            } else {
                document.body.classList.remove('page-scrolled');
            }
        });

        // Mobile menu

        const menu_wrapper = document.querySelector('.page-header');
        const menu_button = menu_wrapper.querySelector('.menu-btn');
        const menu_link = menu_wrapper.querySelectorAll('.menu a');

        // consolevent.log(menu_link);

        if (menu_wrapper && menu_button) {
            menu_button.addEventListener('click', function () {
                menu_wrapper.classList.toggle('opened');
                document.body.classList.toggle('opened');
            });

            menu_link.forEach(function (item) {
                item.addEventListener('click', function () {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');
                });
            });

            window.addEventListener('keyup', function (event) {
                if (event.keyCode == 27 && document.body.classList.contains('opened')) {
                    menu_wrapper.classList.remove('opened');
                    document.body.classList.remove('opened');
                }
            });
        }

        // To top

        const back_to_top_button = document.querySelector('.back-top');

        if (back_to_top_button) {
            document.querySelector('.back-top').addEventListener('click', function () {
                window.scrollTo(0, 0);
            });
        }

        // Modal close

        jQuery('.modal-overlay, .modal .close').on('click', function (e) {
            e.preventDefault();

            jQuery('.modal').addClass('fadeOutUp');
            jQuery('.tint').addClass('fadeOut');
            setTimeout(function () {
                jQuery('.modal').removeClass('fadeInDownBig fadeOutUp');
                jQuery('.tint').removeClass('fadeOut fadeIn');
                jQuery('.modal-overlay').hide();
                jQuery('body').removeClass('noscroll');
                jQuery('.modal .modal-content').hide();
            }, 1200);
        });

        jQuery('.modal-overlay .modal-content').click(function (e) {
            e.stopPropagation();
        });
    },

    _showOverlay: function (name) {
        var body = jQuery('body'),
            overlay = jQuery('.modal-overlay'),
            close = jQuery('.close', overlay),
            tint = jQuery('.tint'),
            modal = jQuery('.modal');

        body.addClass('noscroll');
        overlay.show();
        tint.addClass('fadeIn');
        overlay.attr('class', 'modal-overlay').addClass(name);
        jQuery('.modal-content', overlay).show();
        jQuery('#' + name).show();
        // modal.show();
        modal.addClass('fadeInDownBig');
    },

    _hideOverlay: function () {
        var body = jQuery('body'),
            overlay = jQuery('.modal-overlay'),
            close = jQuery('.close', overlay),
            tint = jQuery('.tint'),
            modal = jQuery('.modal');

        modal.addClass('fadeOutUp');
        tint.addClass('fadeOut');
        jQuery('.modal-trigger.modal-active').removeClass('modal-active');

        setTimeout(function () {
            modal.removeClass('fadeInDownBig fadeOutUp');
            tint.removeClass('fadeOut fadeIn');
            overlay.hide().attr('class', 'modal-overlay');
            body.removeClass('noscroll');
            jQuery('.modal .modal-content').hide();
        }, 1000);
    },

    popups: function () {
        var body = jQuery('body'),
            overlay = jQuery('.modal-overlay'),
            tint = jQuery('.tint'),
            close = jQuery('.close', overlay),
            modal_triggers = jQuery('.modal-trigger'),
            active_item = false;

        // Modal close button
        jQuery('.close').click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            App._hideOverlay();
        });

        // Attach popups event handlers
        modal_triggers.click(function (e) {
            e.preventDefault();

            var self = jQuery(this),
                overlay_id = self.data('modal'),
                img = jQuery('#' + overlay_id + ' .modal-img');

            modal_triggers.filter('.modal-active').removeClass('modal-active');
            self.addClass('modal-active');

            active_item = self;

            if (img.length != 0 && !img.hasClass('loaded')) {
                img.attr('src', img.attr('data-src')).addClass('loaded');
            }

            App._showOverlay(jQuery(this).data('modal'));
        });

        overlay.click(function () {
            App._hideOverlay();
        });

        jQuery('.modal-content', overlay).click(function (e) {
            e.stopPropagation();
        });

        jQuery(window).on('keyup', function (event) {
            if (modal_triggers.filter('.modal-active').length) {
                switch (event.keyCode) {
                    case 37: //left
                        prev_btn.trigger('click');
                        break;
                    case 39: //right
                        next_btn.trigger('click');
                        break;
                    case 27: //esc
                        App._hideOverlay();
                        break;
                }
            }
        });

        // Auto open boarding

        if (window.location.search && window.location.search.indexOf('projekto-pradzia') != -1) {
            document.querySelector('.modal-trigger').click();
        }
    }
};

document.addEventListener('DOMContentLoaded', function () {
    App.init();
});
window.addEventListener('load', function () {
    App.loaded();
});
